// Copyright 2022-2023 the Chili authors. All rights reserved. AGPL-3.0 license.

import { AppBuilder } from "chili-builder";
import { Logger } from "chili-core";
import { Loading } from "./loading";

let loading = new Loading();
console.log('loading: ', loading);
document.body.appendChild(loading);

// prettier-ignore
new AppBuilder()
    .useIndexedDB()
    .useOcc()
    .useThree()
    .useUI()
    .build()
    .then(x => {
        document.body.removeChild(loading)
    })
    .catch((err) => {
        Logger.error(err);
    });

    function getDemoFileContent() {
        return new Promise((resolve, _) => {
            fetch("https://h5ucl.gzshibahao.com/HG18501TOP.igs", {
                // mode:"no-cors"
            }).then(rep => {
                console.log('rep: ', rep);
                return rep.blob()
            }).then(res => {
                console.log('res: ', res);
                let reader = new FileReader();
                reader.onload = (e) => {
                  resolve(e.target!.result)
                };
                reader.readAsText(res)
            }).catch(err => {
                console.log('err: ', err);
            })
        })
    }

    (window as any).getDemoFileContent = getDemoFileContent

    function getDemoFileContent2() {
        return new Promise((resolve, _) => {
            fetch("https://dzcdn.gzshibahao.com/cup%20mold.igs", {
                // mode:"no-cors"
            }).then(rep => {
                console.log('rep: ', rep);
                return rep.blob()
            }).then(res => {
                console.log('res: ', res);
                let reader = new FileReader();
                reader.onload = (e) => {
                  resolve(e.target!.result)
                };
                reader.readAsText(res)
            }).catch(err => {
                console.log('err: ', err);
            })
        })
    }

    (window as any).getDemoFileContent2 = getDemoFileContent2